import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAuth, useCart } from "@saleor/sdk";
import { Link } from "react-router-dom";
import { MenuDropdown, OverlayContext, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import "./scss/index.scss";

var MainMenu = function MainMenu() {
  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var _useCart = useCart(),
      items = _useCart.items;

  var handleSignOut = function handleSignOut() {
    signOut();
  };

  var cartItemsQuantity = items && items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0) || 0; // AJOUTS

  var overlayContext = useContext(OverlayContext);
  var activeDropdown;
  useEffect(function () {
    if (activeDropdown) {
      overlayContext.show(OverlayType.mainMenuNav, OverlayTheme.modal);
    } else {
      overlayContext.hide();
    }
  }, [activeDropdown]);

  var _useState = useState("header-menu-toggle"),
      _useState2 = _slicedToArray(_useState, 2),
      classMenu = _useState2[0],
      setMenu = _useState2[1];

  var _useState3 = useState("site-layout--default"),
      _useState4 = _slicedToArray(_useState3, 2),
      classSiteWrapper = _useState4[0],
      setSiteWrapper = _useState4[1];

  var _useState5 = useState("header-cart-toggle"),
      _useState6 = _slicedToArray(_useState5, 2),
      classCartToggle = _useState6[0],
      setCartToggle = _useState6[1];

  var _useState7 = useState("header-search-toggle"),
      _useState8 = _slicedToArray(_useState7, 2),
      classSearchToggle = _useState8[0],
      setSearchToggle = _useState8[1];

  var _useState9 = useState("header-social-toggle d-none d-md-block"),
      _useState10 = _slicedToArray(_useState9, 2),
      classSocialToggle = _useState10[0],
      setSocialToggle = _useState10[1];

  var classTopBarToggle = "header-top-bar-toggle d-md-none hide";

  var _useState11 = useState(false),
      _useState12 = _slicedToArray(_useState11, 2),
      overlayOpen = _useState12[0],
      setOverlayOpen = _useState12[1];

  var _useState13 = useState(null),
      _useState14 = _slicedToArray(_useState13, 2),
      overlayType = _useState14[0],
      setOverlayType = _useState14[1];

  var toggleClassMenu = function toggleClassMenu() {
    if (classSiteWrapper === "site-layout--default") {
      setSiteWrapper("site-layout--default site-wrapper--has-menu-overlay");
      setMenu("header-menu-toggle toggled");
      setSocialToggle("header-social-toggle d-none d-md-block hide");
      setCartToggle("header-cart-toggle hide");
      setSearchToggle("header-search-toggle hide");
    } else {
      setSiteWrapper("site-layout--default");
      setMenu("header-menu-toggle");
      setSocialToggle("header-social-toggle d-none d-md-block");
      setCartToggle("header-cart-toggle");
      setSearchToggle("header-search-toggle");
    }
  }; // INTERACTION MOBILE


  var _useState15 = useState("mobile-bar-item__header collapsed"),
      _useState16 = _slicedToArray(_useState15, 2),
      classGPUMobile = _useState16[0],
      setGPUMobile = _useState16[1];

  var _useState17 = useState("mobile-bar-item__header collapsed"),
      _useState18 = _slicedToArray(_useState17, 2),
      classASICMobile = _useState18[0],
      setASICMobile = _useState18[1];

  var onClickGPUMobile = function onClickGPUMobile() {
    if (classGPUMobile === "mobile-bar-item__header collapsed") {
      setGPUMobile("mobile-bar-item__header");
    } else {
      setGPUMobile("mobile-bar-item__header collapsed");
    }
  };

  var onClickASICMobile = function onClickASICMobile() {
    if (classASICMobile === "mobile-bar-item__header collapsed") {
      setASICMobile("mobile-bar-item__header");
    } else {
      setASICMobile("mobile-bar-item__header collapsed");
    }
  };

  return React.createElement(OverlayContext.Consumer, null, function (overlayContext) {
    return React.createElement(React.Fragment, null, React.createElement("div", {
      className: classSiteWrapper
    }, React.createElement("header", {
      id: "header",
      className: "site-header site-header--bottom"
    }, React.createElement("div", {
      className: "header-logo header-logo--img"
    }, React.createElement(Link, {
      to: appPaths.baseUrl
    }, React.createElement("img", {
      src: "/assets/img/mm-logo-green.svg",
      srcSet: "/assets/img/logo@2x.png",
      alt: "mining-market"
    }))), React.createElement("nav", {
      className: "main-nav"
    }, React.createElement("ul", {
      className: "main-nav__list"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.baseUrl
    }, "Accueil")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: "javascript:void(0);"
    }, "GPU Mining"), React.createElement("ul", {
      className: "main-nav__sub"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryGpuUrl
    }, "GPUs")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryRigPartsUrl
    }, "RIG Parts")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: appPaths.categoryBuiltRigsUrl
    }, "Built Rigs \xA0", React.createElement("span", {
      className: "badge badge-success"
    }, "New")))), React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: "javascript:void(0);"
    }, "ASIC Mining"), React.createElement("ul", {
      className: "main-nav__sub"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryAsicUrl
    }, "Miners")), React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      to: appPaths.categoryAsicSparePartsUrl
    }, "Spare Parts"))), React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: appPaths.hostedUrl
    }, "Hosted Mining")), React.createElement("li", {
      className: ""
    }, React.createElement("a", {
      href: appPaths.dataUrl
    }, "Data Tools")))), React.createElement("div", {
      className: "header-actions"
    }, React.createElement("div", {
      className: classTopBarToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--close"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#close"
    }))), React.createElement("div", {
      className: classSocialToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--thumb-up"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#thumb-up"
    })), React.createElement("span", {
      className: "header-social-toggle__plus"
    }, "\xA0"), React.createElement("ul", {
      className: "social-menu social-menu--header"
    }, React.createElement("li", null, React.createElement("a", {
      href: "https://discord.gg/GShtcRNeJE"
    }, React.createElement("span", {
      className: "link-subtitle"
    }, "Discord"), "Mining Market")), React.createElement("li", null, React.createElement("a", {
      href: "https://twitter.com/MiningMarket_21"
    }, React.createElement("span", {
      className: "link-subtitle"
    }, "Twitter"), "Mining Market")))), React.createElement("div", {
      onClick: function onClick() {
        if (!overlayOpen || overlayType !== OverlayType.cart) {
          overlayContext.show(OverlayType.cart, OverlayTheme.right);
          setOverlayType(OverlayType.cart);
        } else {
          overlayContext.hide();
          setOverlayType(null);
        }

        setOverlayOpen(!overlayOpen);
      },
      className: classCartToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--bag"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#bag"
    })), React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--close"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#close"
    })), React.createElement("span", {
      className: "header-cart-toggle__items-count"
    }, cartItemsQuantity > 0 ? cartItemsQuantity : null)), React.createElement("div", {
      onClick: function onClick() {
        if (!overlayOpen || overlayType !== OverlayType.search) {
          overlayContext.show(OverlayType.search, OverlayTheme.right);
          setOverlayType(OverlayType.search);
        } else {
          overlayContext.hide();
          setOverlayType(null);
        }

        setOverlayOpen(!overlayOpen);
      },
      className: classSearchToggle
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--search"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#search"
    })), React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--search-close"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#search-close"
    }))), React.createElement(React.Fragment, null, user ? React.createElement("div", {
      className: classSocialToggle
    }, React.createElement(MenuDropdown, {
      head: React.createElement(React.Fragment, null, React.createElement("span", {
        className: "header-social-toggle__plus"
      }, "\xA0"), React.createElement("svg", {
        role: "img",
        className: "df-icon df-icon--logout"
      }, React.createElement("use", {
        xlinkHref: "/assets/img/mm-sprite.svg#logout"
      }))),
      content: React.createElement("ul", {
        className: "social-menu social-menu--header"
      }, React.createElement("li", {
        "data-test": "desktopMenuMyAccountLink"
      }, React.createElement("a", {
        href: appPaths.accountUrl
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "my account"), React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
        "data-test": "desktopMenuOrderHistoryLink"
      }, React.createElement("a", {
        href: appPaths.orderHistoryUrl
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "order history"), React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
        "data-test": "desktopMenuAddressBookLink"
      }, React.createElement("a", {
        href: appPaths.addressBookUrl
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "address book"), React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
        onClick: handleSignOut,
        "data-test": "desktopMenuLogoutLink"
      }, React.createElement("a", {
        href: "javascript:void(0);"
      }, React.createElement("span", {
        className: "link-subtitle"
      }, "log out"), React.createElement(FormattedMessage, commonMessages.logOut))))
    })) : React.createElement("div", {
      className: classSocialToggle,
      onClick: function onClick() {
        if (!overlayOpen || overlayType !== OverlayType.login) {
          overlayContext.show(OverlayType.login, OverlayTheme.right);
          setOverlayType(OverlayType.login);
        } else {
          overlayContext.hide();
          setOverlayType(null);
        }
      }
    }, React.createElement("svg", {
      role: "img",
      className: "df-icon df-icon--account"
    }, React.createElement("use", {
      xlinkHref: "/assets/img/mm-sprite.svg#account"
    })))), React.createElement("div", {
      className: classMenu,
      onClick: toggleClassMenu
    }, React.createElement("div", {
      className: "header-menu-toggle__inner"
    }, React.createElement("span", null, "\xA0"), React.createElement("span", null, "\xA0"), React.createElement("span", null, "\xA0"))))), React.createElement("div", {
      className: "site-overlay"
    }), React.createElement("div", {
      className: "menu-panel"
    }, React.createElement("ul", {
      className: "menu-panel__mobile-bar list-unstyled"
    }, React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      href: "javascript:void(0);",
      className: classGPUMobile,
      "data-toggle": "collapse",
      onClick: function onClick() {
        return onClickGPUMobile();
      },
      role: "button",
      "aria-expanded": classGPUMobile === "mobile-bar-item__header",
      "aria-controls": "mobile_collapse_1"
    }, "GPU Mining ", React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("div", {
      id: "mobile_collapse_1",
      className: "collapse mobile-bar-item__body ".concat(classGPUMobile === "mobile-bar-item__header" ? "collapse show" : "")
    }, React.createElement("nav", {
      className: "mobile-nav"
    }, React.createElement("ul", {
      className: "mobile-nav__list"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      onClick: function onClick() {
        return toggleClassMenu();
      },
      to: appPaths.categoryGpuUrl
    }, "GPUs"), React.createElement(Link, {
      onClick: function onClick() {
        return toggleClassMenu();
      },
      to: appPaths.categoryRigPartsUrl
    }, "Rig Parts"), React.createElement(Link, {
      onClick: function onClick() {
        return toggleClassMenu();
      },
      to: appPaths.categoryBuiltRigsUrl
    }, "Built Rigs")))))), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: classASICMobile,
      "data-toggle": "collapse",
      onClick: function onClick() {
        return onClickASICMobile();
      },
      href: "javascript:void(0);",
      role: "button",
      "aria-expanded": "false",
      "aria-controls": "mobile_collapse_2"
    }, "ASIC Mining ", React.createElement("span", {
      className: "main-nav__toggle"
    }, "\xA0")), React.createElement("div", {
      id: "mobile_collapse_2",
      className: "collapse mobile-bar-item__body ".concat(classASICMobile === "mobile-bar-item__header" ? "collapse show" : "")
    }, React.createElement("nav", {
      className: "mobile-nav"
    }, React.createElement("ul", {
      className: "mobile-nav__list"
    }, React.createElement("li", {
      className: ""
    }, React.createElement(Link, {
      onClick: function onClick() {
        return toggleClassMenu();
      },
      to: appPaths.categoryAsicUrl
    }, "Miners"), React.createElement(Link, {
      onClick: function onClick() {
        return toggleClassMenu();
      },
      to: appPaths.categoryAsicSparePartsUrl
    }, "Spare Parts")))))), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: "hosting"
    }, "Hosted Mining")), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: "data"
    }, "DATA Infos")), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: "contact"
    }, "Contact")), React.createElement("li", {
      className: "mobile-bar-item"
    }, React.createElement("a", {
      className: "mobile-bar-item__header",
      href: appPaths.accountUrl
    }, "Mon compte"))))));
  });
};

export default MainMenu;