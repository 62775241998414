import React from "react";
import { useAuth } from "@saleor/sdk";
import { Loader } from "@components/atoms";
import { MainMenu, OverlayManager, OverlayProvider } from "../components";
import ShopProvider from "../components/ShopProvider";
import "../globalStyles/scss/index.scss";
import { Routes } from "./routes";
import Notifications from "./Notifications";

var App = function App() {
  var _useAuth = useAuth(),
      tokenRefreshing = _useAuth.tokenRefreshing,
      tokenVerifying = _useAuth.tokenVerifying;

  if (tokenRefreshing || tokenVerifying) {
    return React.createElement(Loader, null);
  }

  return React.createElement(React.Fragment, null, React.createElement(ShopProvider, null, React.createElement(OverlayProvider, null, React.createElement("div", {
    className: "d-block wrapper"
  }, React.createElement(Routes, null)), React.createElement(MainMenu, null), React.createElement(OverlayManager, null), React.createElement(Notifications, null))));
};

export default App;