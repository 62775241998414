import React from "react";
import { useIntl } from "react-intl";
import Media from "react-responsive";
import { withRouter } from "react-router";
import { commonMessages } from "@temp/intl";
import { useAuth } from "@saleor/sdk";
import { smallScreen } from "@styles/constants";
import { AccountMenu, AccountMenuMobile } from "@components/molecules";
import { AccountTab, OrdersHistory } from "@pages";
import AddressBook from "../../account/AddressBook/AddressBook";
import { accountUrl, addressBookUrl, baseUrl, orderHistoryUrl } from "../../app/routes";
import { Breadcrumbs, Loader } from "../../components";
import "./scss/index.scss";

var returnTab = function returnTab(path, userDetails, history) {
  var tabContent = React.createElement(React.Fragment, null);

  switch (path) {
    case accountUrl:
      {
        tabContent = React.createElement(AccountTab, null);
        break;
      }

    case addressBookUrl:
      {
        tabContent = React.createElement(AddressBook, {
          user: userDetails
        });
        break;
      }

    case orderHistoryUrl:
      {
        tabContent = React.createElement(OrdersHistory, {
          history: history
        });
        break;
      }

    default:
      tabContent = React.createElement(AccountTab, null);
      break;
  }

  return tabContent;
};

var Account = function Account(_ref) {
  var history = _ref.history,
      match = _ref.match;
  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user,
      loaded = _useAuth.loaded;

  var links = [accountUrl, orderHistoryUrl, addressBookUrl];

  if (!loaded) {
    return React.createElement(Loader, null);
  }

  if (!user) {
    history.push(baseUrl);
  }

  return React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: [{
      link: match.path,
      value: intl.formatMessage(commonMessages.myAccount)
    }]
  }), React.createElement("div", {
    className: "account"
  }, React.createElement(Media, {
    minWidth: smallScreen
  }, React.createElement("div", {
    className: "account__menu"
  }, React.createElement(AccountMenu, {
    links: links,
    active: match.path
  }))), React.createElement(Media, {
    maxWidth: smallScreen - 1
  }, React.createElement("div", {
    className: "account__menu_mobile"
  }, React.createElement(AccountMenuMobile, {
    links: links,
    active: match.path
  }))), React.createElement("div", {
    className: "account__content"
  }, user && returnTab(match.path, user, history))));
};

export default withRouter(Account);